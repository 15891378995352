const networkSettings = {
  97: {
    chainId: "0x61",
    chainName: "BSC Testnet",
    nativeCurrency: {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-testnet.public.blastapi.io"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
  56: {
    chainId: "0x38",
    chainName: "BSC  Mainnet",
    nativeCurrency: {
      name: "Binance Coin",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed1.ninicoin.io/"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
};

export const networkSetup = async (chainId) => {
  // return new Promise((resolve, reject) => {
  //   const provider = window.ethereum;

  //   if (provider) {
  // if (networkSettings.hasOwnProperty(chainId)) {
  //   provider
  //     .request({
  //       method: "wallet_addEthereumChain",
  //       params: [networkSettings[chainId]],
  //     })
  //     .then(resolve)
  //     .catch(reject);
  // } else {
  //   reject(
  //     new Error(`No network settings configured for chainId: '${chainId}'`)
  //   );
  // }
  //     try {
  //       window.ethereum.request({
  //         method: 'wallet_switchEthereumChain',
  //         params: [{ chainId: "0x61" }]
  //       })
  //         .then(resolve)
  //         .catch(reject);
  //     } catch (err) {
  //       if (err.code === 4902) {
  //         window.ethereum.request({
  //           method: 'wallet_addEthereumChain',
  //           params: [networkSettings[chainId]],
  //         })
  //           .then(resolve)
  //           .catch(reject);
  //       }
  //     }
  //   } else {
  //     reject(new Error(`window.ethereum is '${typeof provider}'`));
  //   }
  // });
  try {
    const res = await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x61" }],
    });
  } catch (switchError) {
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          id: 1,
          jsonrpc: "2.0",
          method: "wallet_addEthereumChain",
          params: [networkSettings[chainId]],
        });
      } catch (addError) {
      }
    }
  }
};
