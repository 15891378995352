import React, { useState, useEffect, useRef } from "react";
import { FaAngleDown } from "react-icons/fa"
import axios from "axios";
import {
    useCRANK,
    useDXTC,
    useLP_1,
    useLP_2,
    useBurn,
    useBTCB,
    useWBNB
} from "../hooks/useContract";
import {
    getLP_1Address,
    getLP_2Address,
    getLP_3Address,
    getLP_4Address,
    getLP_5Address,
    getStakingAddress,
    getBurnAddress,
    getBTCBAddress
} from "../utils/addressHelpers";
import abi from "../abi/BTCB.json"
import useWeb3 from "../hooks/useWeb3";
import Web3 from "web3";
import { useWallet } from "@binance-chain/bsc-use-wallet";


function Burn() {
    let closeBtn = useRef("")

    const [curreny, setCurreny] = useState("BTC");
    const [DXTCInCirculation, setDXTCInCirculation] = useState(0);
    const [CRANKInCirculation, setCRANKInCirculation] = useState(0);
    const [crankBurn, setCrankBurn] = useState(0);
    const [BTCBBurn, setBTCBBurn] = useState(0);
    const [WBNBBurn, setWBNBBurn] = useState(0);
    const [crank, setCrankPrice] = useState(0);
    const [dxtc, setDxtcPrice] = useState(0);
    const [bnbPrice, setbnbPrice] = useState(0);
    const [btcPrice, setbtcPrice] = useState(0);
    const [burnAmount, setburnAmount] = useState("");
    const [approve, setApprove] = useState(false);
    const [balance, setBalance] = useState(0);
    const [crankBalance, setcrankBalance] = useState(0);
    const [burnError, setBurnError] = useState("");


    const dxtcContract = useDXTC();
    const crankContract = useCRANK();
    const LP_1Contract = useLP_1();
    const LP_2Contract = useLP_2();
    const BurnContract = useBurn();
    const BTCBContract = useBTCB();
    const WBNBContract = useWBNB();
    const web3 = useWeb3();

    const priceInterval = useRef(0);
    const allInterval = useRef(0);
    const { account } = useWallet();

    function numberWithCommas(x) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const getcrankPrice = async () => {
        let crankBalance = await crankContract.methods.balanceOf(account).call();
        crankBalance = await (crankBalance / 1e18).toFixed(2);
        setcrankBalance(crankBalance);
    }

    const dexCiculationSupply = async () => {
        // await axios
        //     .get(`https://api-testnet.bscscan.com/api?module=stats&action=tokenCsupply&contractaddress=0x2816E2D05EA12d790A3Df1A791d532783361ABE2&apikey=7Y59T2RTXIXZQ88USF5N935XJJ6Z1D6MD4`)
        //     .then(async (response) => {
        let totalSupply = await dxtcContract.methods.totalSupply().call();
        // let value = await dxtcContract.methods.decimals().call();
        let forgeBalance = await dxtcContract.methods.balanceOf(getBurnAddress()).call();
        // let LP2Balance = await dxtcContract.methods.balanceOf(getLP_2Address()).call();
        // let LP3Balance = await dxtcContract.methods.balanceOf(getLP_3Address()).call();
        // let LP4Balance = await dxtcContract.methods.balanceOf(getLP_4Address()).call();
        // let LP5Balance = await dxtcContract.methods.balanceOf(getLP_5Address()).call();
        // let deadBalance = await dxtcContract.methods.balanceOf("0x0000dE00000000Ad000000000000DE00000000AD").call();
        let contract1 = await dxtcContract.methods.balanceOf("0x5888180f0df043c9d1ff16d1036ba0fbdbc95177").call();
        // let contract2 = await dxtcContract.methods.balanceOf("0xf6e05fd0925009c0d18c9dcdc1e206e4474b2e33").call();
        // let price = response.data.result ? parseInt(response.data.result) : 0;
        // let price = 21000000000000000000000000000000;
        // StakingToken = await parseInt(StakingToken) / 10 ** 18;
        contract1 = await parseInt(contract1) / 10 ** 18;
        // LP3Balance = await parseInt(LP3Balance) / 10 ** 18;
        // LP4Balance = await parseInt(LP4Balance) / 10 ** 18;
        // LP5Balance = await parseInt(LP5Balance) / 10 ** 18;
        // deadBalance = await parseInt(deadBalance) / 10 ** 18;
        totalSupply = await parseInt(totalSupply) / 10 ** 18;
        forgeBalance = await parseInt(forgeBalance) / 10 ** 18;
        // value = await parseInt(value);
        // await sleep(2000)
        let supply = await (totalSupply - forgeBalance - contract1).toFixed(2);
        supply = await numberWithCommas(supply)
        await setDXTCInCirculation(supply)
        // })
        // .catch((error) => {
        //     console.log(error.response)
        // })
    }

    const CRANKCiculationSupply = async () => {
        // await axios
        //     .get(`https://api-testnet.bscscan.com/api?module=stats&action=tokenCsupply&contractaddress=0x6ED852dC8Cdf90c895C05585135B1bE6b876b65b&apikey=7Y59T2RTXIXZQ88USF5N935XJJ6Z1D6MD4`)
        //     .then(async (response) => {
        // let value = await crankContract.methods.decimals().call();
        let burnToken = await crankContract.methods.balanceOf("0x0000dE00000000Ad000000000000DE00000000AD").call();
        let pinkSale = await crankContract.methods.balanceOf("0x407993575c91ce7643a4d4ccacc9a98c36ee1bbe").call();
        let StakingToken = await crankContract.methods.balanceOf(getStakingAddress()).call();
        let LP1Balance = await crankContract.methods.balanceOf(getLP_1Address()).call();
        // let price = response.data.result ? parseInt(response.data.result) : 0;
        let price = 100000000000000000000000000000;
        StakingToken = await parseInt(StakingToken) / 10 ** 18;
        LP1Balance = await parseInt(LP1Balance) / 10 ** 18;
        burnToken = await parseInt(burnToken) / 10 ** 18;
        pinkSale = await parseInt(pinkSale) / 10 ** 18;
        // value = await parseInt(value);
        let supply = await (Math.round(price / 10 ** 18) - pinkSale - LP1Balance - burnToken - StakingToken).toFixed(2);
        supply = await numberWithCommas(supply)
        await setCRANKInCirculation(supply)
        // })
        // .catch((error) => {
        //     console.log(error.response)
        // })
    }

    const burnTokens = async () => {
        let burnCrank = await crankContract.methods.balanceOf("0x0000dE00000000Ad000000000000DE00000000AD").call();
        let burnBTCB = await BTCBContract.methods.balanceOf("0x0000dE00000000Ad000000000000DE00000000AD").call();
        let burnWBNB = await web3.eth.getBalance("0x0000dE00000000Ad000000000000DE00000000AD");
        burnCrank = await (parseInt(burnCrank) / 10 ** 18).toFixed(4);
        burnBTCB = await (parseInt(burnBTCB) / 10 ** 18).toFixed(4);
        burnWBNB = await (parseInt(burnWBNB) / 10 ** 18).toFixed(4);
        burnCrank = await numberWithCommas(burnCrank);
        burnBTCB = await numberWithCommas(burnBTCB);
        burnWBNB = await numberWithCommas(burnWBNB);
        await setCrankBurn(burnCrank);
        await setBTCBBurn(burnBTCB);
        await setWBNBBurn(burnWBNB);
    }

    const isApproved = async () => {
        if (curreny == "BTC") {
            let approvedBTCB = await BTCBContract.methods.allowance(account, getBurnAddress()).call();
            approvedBTCB = await web3.utils.fromWei(approvedBTCB, 'ether');
            approvedBTCB = await parseInt(approvedBTCB);
            console.log(approvedBTCB);
            await setApprove(approvedBTCB)
        } else {
            await setApprove(0)
        }
    }

    const getCrankPrice = async () => {
        await axios
            .get(`https://api-testnet.bscscan.com/api?module=stats&action=bnbprice&apikey=7Y59T2RTXIXZQ88USF5N935XJJ6Z1D6MD4`)
            .then(async (response) => {
                let value = await LP_1Contract.methods.getReserves().call();
                let price = response.data.result.ethusd ? parseFloat(response.data.result.ethusd) : 0;
                let btcPrice = response.data.result.ethbtc ? parseFloat(response.data.result.ethbtc) : 0;
                await setbnbPrice(price.toFixed(2))
                await setbtcPrice((price / btcPrice).toFixed(2))
                let crankPrice = await ((parseInt(value["_reserve1"]) / parseInt(value["_reserve0"]))).toFixed(8)
                await setCrankPrice(crankPrice)
            })
            .catch((error) => {
                console.log(error.response)
            })
    }

    async function burn() {
        let token = "";
        if (curreny == "BTC") {
            token = getBTCBAddress();
            let amount = await burnAmount.toString();
            amount = await web3.utils.toWei(amount, 'ether');
            await BurnContract.methods
                .burnERC20(
                    token,
                    amount
                )
                .send({ from: account, value: 0 })
                .on('confirmation', function (confirmationNumber, receipt) {
                    setTimeout(() => {
                        getBalance();
                        closeBtn.current.click();
                    }, 2000);
                });
        } else if (curreny == "BNB") {
            let amount = await burnAmount.toString();
            amount = await web3.utils.toWei(amount, 'ether');
            console.log(amount)
            await BurnContract.methods
                .execute()
                .send({ from: account, value: amount })
                .on('confirmation', function (confirmationNumber, receipt) {
                    setTimeout(() => {
                        getBalance();
                        closeBtn.current.click();
                    }, 2000);
                });
        } else {
            alert("Please select coin");
        }

    }

    async function approveToken() {
        let token = getBurnAddress();
        if (curreny == "BTC") {
            await BTCBContract.methods
                .approve(
                    token,
                    "9877665543223456678990988766543000000000000000000"
                )
                .send({ from: account, value: 0 })
                .on('confirmation', function (confirmationNumber, receipt) {
                    setTimeout(() => {
                        isApproved()
                    }, 2000);
                });
        } else {
            return
        }
    }

    const prepareContract = async (abi, address) => {
        let web3 = await new Web3()
        await web3.setProvider(
            new web3.providers.HttpProvider(
                `https://bsc-dataseed1.ninicoin.io/`,
            ),
        )
        const contract = await new web3.eth.Contract(
            abi,
            address
        )
        return { contract, web3 };
    }

    const getBalance = async () => {
        const { contract, web3 } = await prepareContract(abi.abi, getBTCBAddress());
        if (curreny == "BTC") {
            let balance = await contract.methods.balanceOf(account).call();
            balance = await (balance / 1e18).toFixed(4)
            await setBalance(balance);
            console.log(balance);
        } else if (curreny == "BNB") {
            let balance = await web3.eth.getBalance(account);
            balance = await (balance / 1e18).toFixed(4)
            await setBalance(balance)
            console.log(balance);
        } else {
            setBalance(0)
        }
    }

    const burnAmountSet = async (e) => {
        setburnAmount(e);
        setBurnError("");
        if (e) {
            if (!(parseFloat(e) <= parseFloat(balance))) {
                setBurnError("Insufficent Funds");
            }
        }
    }


    useEffect(() => {
        dexCiculationSupply()
        CRANKCiculationSupply()
        burnTokens()
        getCrankPrice()
        getcrankPrice()
        window.interval23 = setInterval(
            () => {
                dexCiculationSupply()
                CRANKCiculationSupply()
                burnTokens()
                if (account) {
                    isApproved();
                    getcrankPrice();
                }
            },
            45000
        )
        return () => {
            clearInterval(window.interval23)
        }
    }, []);

    useEffect(() => {
        window.interval234 = setInterval(
            () => {
                getCrankPrice();
                if (account) {
                    getBalance();
                }
            },
            30000
        )
        return () => {
            clearInterval(window.interval234)
        }
    }, []);

    useEffect(() => {
        if (account) {
            getBalance();
            isApproved();
            getcrankPrice();
        }
    }, [account, curreny]);

    return (
        <>
            <div className="container-fluid mt-5">
                <div className="row justify-content-center my-5">
                    <div className="col-lg-4 mb-4">
                        <a href="https://bscscan.com/token/0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4" target="_blank">
                            <div className="statsCard burnSwap ">
                                <p>DXO FORGED</p>
                                <h3>{DXTCInCirculation}</h3>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <a href="https://bscscan.com/token/0x95c2e87f4ec3e97558c1306faf155a3ae7576ded?a=0x0000dE00000000Ad000000000000DE00000000AD" target="_blank">
                            <div className="statsCard burnSwap ">
                                <p>BTC BURNED</p>
                                <h3>{BTCBBurn}</h3>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <a href="https://bscscan.com/address/0x0000dE00000000Ad000000000000DE00000000AD" target="_blank">
                            <div className="statsCard burnSwap ">
                                <p>BNB BURNED</p>
                                <h3>{WBNBBurn}</h3>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <a href="https://bscscan.com/token/0x5DE56c867692277fAaF6756D6d30Eba2086E948E" target="_blank">
                            <div className="statsCard burnSwap ">
                                <p>BYAS in circulation</p>
                                <h3>{CRANKInCirculation}</h3>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-4 mb-4">
                        <a href="https://bscscan.com/token/0x5DE56c867692277fAaF6756D6d30Eba2086E948E?a=0x0000dE00000000Ad000000000000DE00000000AD" target="_blank">
                            <div className="statsCard burnSwap ">
                                <p>BYAS BURNED</p>
                                <h3>{crankBurn}</h3>
                            </div>
                        </a>
                    </div>

                    <div className="col-lg-4 mb-4">
                        <a href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x5DE56c867692277fAaF6756D6d30Eba2086E948E" target="_blank">
                            <div className="statsCard burnSwap ">
                                <p>BYAS Price</p>
                                <div className="d-flex justify-content-between ">
                                    <h3>${crank}</h3>
                                    <p className="mb-0 btn btn_m_s no-rap">Click to Buy</p>
                                </div>
                            </div>
                        </a>
                    </div>


                    <div className="col-lg-7 my-5">
                        <div className={`burnSwap p-4 ${crankBalance > 100000 ? "" : "rainbow"}`}>
                            <div className="text-left">
                                <h3>Burn</h3>
                                <p>Not Working? <a href="https://satoshis-brother.gitbook.io/thebitcoinstandard/" target="_blank" className="text-primary">Read Docs Or you will be lost</a></p>
                            </div>
                            <div className="text-right mb-4">
                                <p className="mb-0">Balance : <b>{balance} {curreny}</b></p>
                                <p className="mb-0">Price : <b>${curreny == "BNB" ? bnbPrice : btcPrice}</b></p>
                                <p>Total value : <b>${curreny == "BNB" ? (bnbPrice * burnAmount).toFixed(4) : (btcPrice * burnAmount).toFixed(4)}</b></p>
                            </div>
                            <div className="my-3">

                                <div className="d-flex justify-content-center align-items-end ">
                                    <div className=" numberContainer">
                                        <p className="ps-2 mb-2">Amount: </p>
                                        <input
                                            className="currencyInputField currencyInput"
                                            placeholder="0.0"
                                            type="number"
                                            onChange={(e) => burnAmountSet(e.target.value)}
                                            value={burnAmount}
                                        />
                                    </div>
                                    <div className="tokenContainer currencyInput mx-3">
                                        <div className="dropdown currencyDropdown">
                                            <div className="d-flex justify-content-center align-items-center" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <div className="currencyInputField px-3 d-flex align-items-center" >
                                                    {curreny} <FaAngleDown className="ms-2 size_icn" />
                                                </div>
                                            </div>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><span className="dropdown-item" onClick={() => setCurreny("BTC")}>BTC</span></li>
                                                <li><span className="dropdown-item" onClick={() => setCurreny("BNB")}>BNB</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <p className="text-center pt-4 text-danger fw-bold"><small>{burnError}</small></p>
                            </div>
                            <div className="text-center mt-5">
                                {(approve > 0 || curreny == "BNB")
                                    ? <>
                                        <button className={`btn px-5 py-3 btn_m_s no-rap`} data-bs-toggle="modal" data-bs-target="#exampleModal3" ><h5 className="mb-0">Forge</h5></button>
                                    </>
                                    : <button className="btn px-5 py-3 btn_m_s no-rap " onClick={approveToken} ><h5 className="mb-0">Approve</h5></button>
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            <div class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered color1">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel"></h5>
                            <button type="button" ref={closeBtn} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <p className="text-center"><b>You will receive {curreny == "BNB" ? ((bnbPrice * burnAmount)).toFixed(4) : ((btcPrice * burnAmount)).toFixed(4)} DXO token(s) +/- slippage fee by burning this token</b></p>
                            <div className="text-center">
                                <button className="btn px-4 mt-3 py-2 btn_m_s no-rap " onClick={burn} ><b>Forge</b></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
export default React.memo(Burn)