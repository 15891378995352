import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "jquery";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import Home from "./Pages/Home";
import Dashboard from "./Pages/Dashboard";
import Launch from "./Pages/Launch";
import "./index.css";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Launch />}></Route>
        <Route exact path="/stats" element={<Dashboard />}></Route>
        <Route exact path="/pools" element={<Home />}></Route>
      </Routes>
    </>
  );
}

export default App;
