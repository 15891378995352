import { Link } from "react-router-dom";
import video from "../video/Untitled.mp4";
// import video1 from "../video/Untitled.ogg";
import { Player, ControlBar, ReplayControl } from 'video-react';
import { useEffect } from "react";
import { TbBrandTelegram } from "react-icons/tb";
import { TfiTwitter } from "react-icons/tfi";
import { SlDocs } from "react-icons/sl";
import { FaDotCircle } from "react-icons/fa";

function Docs() {
    useEffect(() => {
        setTimeout(() => {
            document.querySelector('button.video-react-play-control').click()
        }, 5000);
    })

    return (
        <>
            <div className="bg-black">
                <video className="vediotag" playsInline autoPlay={true} muted={true} >
                    <source src={video} type="video/mp4" />
                </video>
                <div className="w-100 px-lg-5 px-2 mt-0 my-0 text-center">
                    {/* <p className="text-white linkP px-6 text-center mb-0 mt-0  mx-auto">All collateralized stable coins have accounting deficiencies and can't always guarantee that they are 1:1 pegged to the assets they are backed by.<br /><br /> DXO is the first un-stable coin that can only be created in the Forge by burning BTC. Every single DXO is instantly verifiable with no accounting necessary since it was created on chain by burning Bitcoin.<br /><br /> DXO is impervious to changes in the crypto market since it's not a collateralized asset.</p> */}

                    <p className="text-white linkP px-6 text-center mb-0 mt-0  mx-auto">All collateralized stable coins will eventually die.<br /><br /> Let's use Bitcoin to <i>Forge</i> the hardest most reliable un-stable coin ever.<br /><br /> DXO is the first un-stable coin that can only be created in the <i>Forge</i> by burning BTC.<br /><br /> Every single DXO is instantly verifiable with no accounting necessary since it was created on chain by burning Bitcoin.<br /><br /> DXO is impervious to changes in the crypto market since it's not a collateralized asset.</p>
                    <a className="text-white my-3 d-block" href="https://docs.dxocoin.com/" target="_blank">  Read the whitepaper <SlDocs size={20} className="ms-2 docsIcon" /></a>
                    <p>
                        <h5 className="text-left d-inline-block heading-1 py-4 font-C">
                            <h2 className="text-center pb-2">Why Use DXO?</h2>
                            <ul className="dxoList">
                                <li>DXO pays a yield directly in your wallet</li>
                                <li>DXO is always 100% verifiable</li>
                                <li>DXO makes Bitcoin green</li>
                                <li>DXO makes Bitcoin transactable even for a cup of coffee</li>
                            </ul>
                        </h5>
                    </p>
                    <div className="py-2 ">
                        <a className="mx-2 text-white" href="https://bscscan.com/token/0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4" target="_blank"><img src="/bscscan.png" width="100px" /></a> <small className="text-white">0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4</small>
                    </div>
                    {/* <div className="py-3 ">
                        <a className="mx-3 text-white" href="https://t.me/byascommunity" target="_blank"><TbBrandTelegram size={25} /></a>
                        <a className="mx-3 text-white" href="https://twitter.com/Byas_Community" target="_blank"><TfiTwitter size={25} /></a>
                    </div> */}
                </div>
                <div className="LinkOuter w-100">
                    <div className="w-100 px-5 d-lg-block d-md-block d-none">
                        <div className="text-right">
                            <div className="px-3 d-inline-block">
                                <div className="py-3 ">
                                    <a className="mx-3 text-white" href="https://t.me/byascommunity" target="_blank"><TbBrandTelegram size={25} /></a>
                                    <a className="mx-3 text-white" href="https://twitter.com/Byas_Community" target="_blank"><TfiTwitter size={25} /></a>
                                </div>
                            </div>
                            <Link className="LinkInner btn btn_m_s no-rap px-4 py-2" to="/stats">Launch App</Link>
                            <a className="LinkInner btn btn_m_s no-rap px-4 py-2 ms-3" href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&amp;outputCurrency=0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4" target="_blank">Buy DXO</a>
                        </div>
                    </div>
                    <div className="w-100 px-3 d-lg-none d-md-none">
                        <div className="text-right flex flex-nowrap">
                            <div className="px-1 d-inline-block">
                                <div className="py-3 ">
                                    <a className="mx-2 text-white" href="https://t.me/byascommunity" target="_blank"><TbBrandTelegram size={25} /></a>
                                    <a className="mx-2 text-white" href="https://twitter.com/Byas_Community" target="_blank"><TfiTwitter size={25} /></a>
                                </div>
                            </div>
                            <Link className="LinkInner btn btn_m_s no-rap px-4 py-2" to="/stats">Launch App</Link>
                            <a className="LinkInner btn btn_m_s no-rap px-4 py-2 ms-3" href="https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&amp;outputCurrency=0xb39b58014A43dc1Da820dBc2a3BF4dBAE08206A4" target="_blank">Buy DXO</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Docs;
